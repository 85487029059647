import React from 'react';
import logo from './logo_white.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          We are currently experiencing issues with one of our data centers.
          <br/>
          Please bear with us, and your services will be back online shortly.
        </p>
        <a
          className="App-link"
          href="https://nexvortex.kayako.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact a Support Specialist
        </a>
      </header>
    </div>
  );
}

export default App;
